<template>
	<div class="container">

    <div
        data-te-modal-init
        class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id="ConfirmRegistrationModal"
        tabindex="-1"
        aria-labelledby="ConfirmRegistrationModalLabel"
        aria-modal="true"
        role="dialog">
      <div
          data-te-modal-dialog-ref
          class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]">
        <div
            class="pointer-events-auto relative flex w-full flex-col rounded-md border-none  bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
          <div
              class="flex flex-shrink-0 items-center justify-between rounded-t-md  p-4 dark:border-opacity-50">
            <!--Modal title-->
            <!--Close button-->

          </div>

          <!--Modal body-->
          <div class="grid grid-cols-12">
            <div class="col-span-12 lg:col-span-3 p-2" >
              <div id="choose_betaling" class="card noborder divcenter">
                <div class="card-header">
                  <button
                      @click="closeModal"
                      type="button"
                      class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                      data-te-modal-dismiss
                      aria-label="Close">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="h-6 w-6">
                      <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div class="card-body p-4">
                  <h3>{{ $t('aanmelding.Kies uw betaalmethode') }}</h3>
                  <el-radio v-if="showFactuurOptie"
                            v-model="betaling"
                            label="factuur"
                            v-on:change.native="set_betaling"
                  >{{ $t('aanmelding.factuur') }}
                  </el-radio><br/>
                  <el-radio v-if="showIdeal"
                            v-model="betaling"
                            label="iDeal"
                            v-on:change.native="set_betaling"
                  >{{ $t('aanmelding.ideal') }}
                  </el-radio><br/>
                  <el-radio v-if="showCreditcard"
                            v-model="betaling"
                            label="Creditcard"
                            v-on:change.native="set_betaling"
                  >{{ $t('aanmelding.creditcard') }}
                  </el-radio><br/>
                  <button
                      type="button"
                      :disabled="buttonDisabled"
                      v-on:click="afronden"
                      class="inline-block rounded bg-project-default px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200 disabled:opacity-70 disabled:bg-red-600"
                      data-te-modal-dismiss
                      data-te-ripple-init
                      data-te-ripple-color="light">
                    {{ btn_afronden }}
                  </button>
                </div>
              </div>
            </div>
            <div class="col-span-12 lg:col-span-9 p-2" >
              <div class="card noborder divcenter">
                <div class="card-body p-4">
                  <h3>{{ $t('aanmelding.Uw aanmelding') }}</h3>
                  <Bill ></Bill>
                </div>
              </div>
              <div class="card noborder divcenter mt-2">
                  <CustomerData></CustomerData>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


	</div>
</template>

<script>
	import FormulierFactory from './registrationform_vsc/FormulierFactoryComponent';
    import Issuer from './IssuerComponent';
    import AanmeldingModalMethods from '../methods/AanmeldingModalMethods';
    import Bill from './bill_vsc/BillComponent';
    import CustomerData from './details/CustomerDataComponent';
    import {
      Modal,
      initTE,
    } from "tw-elements";
    import language from '../methods/lang';

    export default {
        mounted() {
            console.log('RegistrationConfirm modal mounted.');
            let currentObj = this;
            AanmeldingModalMethods.openTweModal(currentObj,'ConfirmRegistrationModal', initTE,Modal);
            this.setBetalingByStore();
        },
        data() {
            return {
              betaling: '',
              mijn_gegevens_form:'',
              buttonDisabled: false,
              buttonClass: 'inline-block rounded bg-project-default px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200',
              currentModal: '',
            };
        },
        methods: {
        	afronden: function(){
                let currentObj = this;
                let store = this.$store;
                let params = [];
                store.dispatch('setBetaling',currentObj.betaling);
        		switch(this.betaling){
        			case 'factuur':
        				// betaling opsturen
                        store.dispatch('setDefinitief');
        				currentObj.$parent.$router.push({ path: '/aanmelding_afronden', query: { lang: this.lang }});
        			break;
        			case 'Creditcard':
                        store.dispatch('mailBeforePayment');
                        params = this.getParamsCreditcard();
                        AanmeldingModalMethods.openNewWindow(params,'/gotoCreditcard');
                    break;
        			case 'iDeal':
                        store.dispatch('mailBeforePayment');
                        params = this.getParamsIdeal();
        				AanmeldingModalMethods.openNewWindow(params,'/gotoIdeal');
        			break;
        		}
        	},
        	set_betaling: function(){
        		let store = this.$store;
        		let currentObj = this;
        		store.dispatch('setBetaling',currentObj.betaling);
                this.setStatusButton();
        	},
            setStatusButton(){
                let currentObj = this;
                currentObj.statusButton('active');
                if(currentObj.betaling=='iDeal'&&currentObj.selected_issuer==''){
                    currentObj.statusButton('inactive');
                }
            },
            statusButton(status){
                if(status == 'inactive'){
                    this.buttonDisabled = true;
                    // this.buttonClass = 'btn btn-danger disabled';
                    return;
                }
                this.buttonDisabled = false;
                // this.buttonClass = 'btn btn-success';
            },
            getParamsCreditcard(){
                let store = this.$store;
                let parameters = [{name:'aanmelding_id',value:store.getters.getAanmelding.aanmelding.id}];
                return parameters;
            },
            getParamsIdeal(){
                let store = this.$store;
                let parameters = [  {name:'aanmelding_id',value:store.getters.getAanmelding.aanmelding.id},
                            ];
                return parameters;
            },
            displayCreditcard(){
                return false;
                return this.showCreditcard;
            },
            setBetalingByStore(){
                let store = this.$store;
                let betaling = store.getters.getBetaling;
                if(betaling==''||betaling==undefined||betaling=='null'){
                    betaling = 'factuur';
                }
                if(betaling == 'factuur'&&!this.showFactuurOptie){
                    betaling = 'iDeal';
                }
                this.betaling = betaling;
                this.setStatusButton();
            },
            closeModal: function(){
              this.currentModal.hide();
            }
        },
        components: {
            FormulierFactory,
            Bill,
            CustomerData
        },
        computed: {
              btn_afronden: function(){
                switch(this.betaling){
                    case 'factuur':
                        return this.$t('aanmelding.Afronden');
                    break;
                    case 'Creditcard':
                    case 'iDeal':
                        return this.$t('aanmelding.betalen');
                    break;
                }
              },
              items: function(){
               	 return this.$store.getters.getAanmeldOpties.models;
              },
              showFactuurOptie: function(){
                return this.$store.getters.getFactuurAllowed;
              },
              showIdeal: function(){
                if(parseFloat(this.$store.getters.getBill.totaal.replace(',', '.')) <= 0){
                    return false;
                }
                return this.$store.getters.getIdealAllowed;
              },
              showCreditcard: function(){
                if(parseFloat(this.$store.getters.getBill.totaal.replace(',', '.')) <= 0){
                    return false;
                }
                return this.$store.getters.getCreditcardAllowed;
              },
              lang: function(){
                return this.$store.getters.getLang;
              }
        },
        created() {
            this.$store.watch(
              (state, getters) => getters.getBetaling,
              () => {
                this.setBetalingByStore();
              },
            );

        },
    }
</script>




