<template>
  <div class="form-group grid grid-cols-12 gap-4" v-bind:for="item.id">
    <div :class="firstColumnMdClass">
	    		<span class="" style="line-height: 24px" v-html="naamTranslated">
	    		</span>
      <Toelichting :item="item"></Toelichting>
    </div>
    <div :class="secondColumnMdClass">
      <div>
        <el-radio-group v-model="checkedValue" v-on:input="clickCheckbox">
          <el-radio v-for="item in Options()"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
          ><span v-html="item.value"></span><br/></el-radio>
        </el-radio-group>
      </div>

    </div>
  </div>
</template>

<script>
import language from '../../methods/lang';
import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
import Toelichting from './ToelichtingComponent';
import _ from 'lodash';

export default {
  props: ['item', 'lang'],
  mounted() {
    console.log('radiocomponent mounted.');
    this.setChecked();
  },
  computed: {
    naamTranslated: language.naamTranslated,
    toelichtingTranslated: language.toelichtingTranslated,
    firstColumnMdClass: function(){
      if(this.item.naam.includes(' uur')){
        return 'col-span-12 md:col-span-1 flex items-start';
      }
      return 'col-span-12 md:col-span-4 flex items-start';
    },
    secondColumnMdClass: function(){
      if(this.item.naam.includes(' uur')){
        return 'col-span-12 md:col-span-11 flex items-start';
      }
      return 'col-span-12 md:col-span-8';
    },
  },
  methods: {
    Options() {
      let temp = this.item.opties.split(';');
      let optionArr = [];
      _.forEach(temp, function (value) {
        optionArr.push({'value': value.trim(), 'label': value.trim()});
      });
      return optionArr;
    },
    clickCheckbox: function (data) {
      if (aanmeldingEntryMethods.aanmeldingEntryExists(this)) {
        aanmeldingEntryMethods.deleteAanmeldingEntry(this);
      }
      aanmeldingEntryMethods.createAanmeldingEntry(this, data);
    },
    setChecked: function () {
      let currentObj = this;
      let store = this.$store;
      if (aanmeldingEntryMethods.aanmeldingEntryExists(this)) {
        this.aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
        this.checkedValue = this.aanmeldingEntry.value;
        return;
      }
      this.checkedValue = '';
    }
  },
  data() {
    return {
      aanmeldingEntry: undefined,
      checkedValue: ''
    }
  },
  created() {
    this.$store.watch(
        (state, getters) => getters.getAanmeldEntries.models,
        () => {
          this.setChecked();
        },
    );
    this.$store.watch(
        (state, getters) => getters.getCurrentSessieId,
        () => {
          this.setChecked();
        }
    );
  },
  components: {
    Toelichting
  }
}
</script>
