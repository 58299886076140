<template>
	    <el-input-number v-model="aantal"  size="mini" :disabled="disabled_attr" v-on:keyup="validate" v-on:change="updateAantal"  :min="1" :max="10"></el-input-number>
</template>

<script>
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
    export default {
    	props: ['item'],
        mounted() {
            console.log('countercomponent mounted.');
            this.setAantal();
        },
        methods: {
        	setAantal: function(){
                let currentObj = this;
                let store = this.$store;
                if(aanmeldingEntryMethods.aanmeldingEntryExists(this)){
                    this.aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
                    this.aantal = this.aanmeldingEntry.aantal;
                    return;
                }
        		if(this.item.aantal>1){
        			this.aantal = this.item.aantal;
        			return;
        		}
        		this.aantal = 1;
        	},
        	updateAantal: function(event){
        		let currentObj = this;
        		let store = this.$store;
        		if(aanmeldingEntryMethods.aanmeldingEntryExists(this)){
        			this.aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
        			this.aanmeldingEntry.aantal = this.aantal;
        			store.dispatch('updateAanmeldingEntry',this.aanmeldingEntry);
        			return;
        		};
        	},
            validate: function() {
                var min = 1;
                var max = 10;
                if (this.aantal < min) this.aantal = min;
                if (this.aantal > max) this.aantal = max;
            }
        },
        computed: {
			disabled_attr: function(){
        		let currentObj = this;
        		if(aanmeldingEntryMethods.aanmeldingEntryExists(this)){
        			return false;
        		}
        		return true;
        	},
        },
        data(){
          return {
	        aantal:1
	      }
        }
    }
</script>
