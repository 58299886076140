<template>
	    <div class="form-group grid grid-cols-12 gap-4" v-bind:for="item.id">
	    	<div class="col-span-12 md:col-span-4 flex items-start">
	    		<span class="mt-1" v-html="naamTranslated"></span>
	    		<Toelichting :item="item"></Toelichting>
	    	</div>
	    	<div class="col-span-12 md:col-span-8">
	    		<div class="el-form-item">
	    			<el-input
	    			  ref="ElInput"
	    			  :style="this.component_style"
	    			  :error="error_message"
					  :name="item.id.toString()"
					  :placeholder="$t('aanmelding.Vul in')"
					  :rules="[
			                { validator: this.validateVoucher(),  trigger: 'blur' }
			            ]"
					  v-model="voucher" v-on:keyup.native="update_value" v-on:paste.native="update_value_on_paste">
					</el-input>
	    		</div>
	    	</div>
	    </div>
</template>

<script>
	import language from '../../methods/lang';
	import _ from 'lodash';
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
	import Toelichting from './ToelichtingComponent';
    export default {
    	props: ['item','lang'],
        mounted() {
            console.log('vouchercomponent mounted.');
            this.set_input_value();
        },
        computed: {
		    naamTranslated: language.naamTranslated,
		    toelichtingTranslated: language.toelichtingTranslated,
		    error_message: {
		    	get: function() {
		    			let aanmeldingEntryExists = aanmeldingEntryMethods.aanmeldingEntryExists(this);
			    		if(aanmeldingEntryExists){
		        			let aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
		        			if(!aanmeldingEntry.voucher_valid){
		        				return aanmeldingEntry.voucher_message;
		        			}
		        		}
		        		return '';
	        	},
	        	set: function(){

	        	}
		    }
        },
        methods: {
        	update_value: function(event){
        		let currentObj = this;
        		let store = this.$store;
                let exists = aanmeldingEntryMethods.aanmeldingEntryExists(this);
                if(exists&&this.voucher==''){
                    this.aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
                    aanmeldingEntryMethods.deleteAanmeldingEntry(this).then(function(){
                        aanmeldingEntryMethods.delay(2000).then(function(){aanmeldingEntryMethods.refreshPrijsEntries(currentObj)});
                    });
                    return;
                }
        		if(exists){
                    aanmeldingEntryMethods.updateAanmeldingEntry(this).then(function(){
                        aanmeldingEntryMethods.delay(2000).then(function(){aanmeldingEntryMethods.refreshPrijsEntries(currentObj)});
                    });
        			// this.aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
        			// this.aanmeldingEntry.value = this.voucher;
        			// store.dispatch('updateAanmeldingEntry',currentObj.aanmeldingEntry);
        			// window.setTimeout(function() {
        			// 	currentObj.aanmeldingEntry.value = currentObj.voucher;
        			// 	store.dispatch('updateAanmeldingEntry',currentObj.aanmeldingEntry);
        			// },2000);
        			return;
        		};
        		aanmeldingEntryMethods.createAanmeldingEntry(this,this.voucher).then(function(){
                    aanmeldingEntryMethods.delay(2000).then(function(){aanmeldingEntryMethods.refreshPrijsEntries(currentObj)});
                });
        	},
        	update_value_on_paste: function(event){
        		let currentObj = this;
	        	window.setTimeout(function(event) { currentObj.update_value(event) },
				  1000);
        	},
        	set_input_value: function(){
        		let currentObj = this;
        		let store = this.$store;
        		if(aanmeldingEntryMethods.aanmeldingEntryExists(this)){
        			this.aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
        			this.voucher = this.aanmeldingEntry.value;
        			if(!this.aanmeldingEntry.voucher_valid){
        				this.error_message = this.aanmeldingEntry.voucher_message;
        			}else{
        				this.error_message = '';
        			}
        			return;
        		};
        		this.voucher = '';
        	},
		    validateVoucher: function() {
		    	if(this.error_message!=''){
			    	if(typeof(this.$refs.ElInput)!= 'undefined'){
			    		$('.el-form-item').addClass('is-error');
			    		$($('.el-form-item__error')).remove();
			    		$(this.$refs.ElInput.$el).append('<div class="el-form-item__error">'+this.error_message+'</div>');
			    	}
		    		return false;
		    	}
		    	if(typeof(this.$refs.ElInput)!= 'undefined'){
			    		$('.el-form-item').removeClass('is-error');
			    		$($('.el-form-item__error')).remove();
			    }
		    	return true;
		    },
		    validate: function(rule, value, callback){
		    	console.dir(value);
		    }
        },
        data() {
	      return {
	        voucher: '',
	        'component_style': ''
	      }
	    },
        created() {
            this.$store.watch(
                (state, getters) => getters.getAanmeldEntries.models,
                () => {
                    this.set_input_value();
                },
            );
            this.$store.watch(
                (state, getters) => getters.getCurrentSessieId,
                () => {
                    this.set_input_value();
                }
            );
        },
	    components: {
  			Toelichting
	    }
    }
</script>
