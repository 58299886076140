<template>
	<div class="container">
    <!--Verically centered modal-->
    <div
        data-te-modal-init
        class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id="loginModalAjax"
        tabindex="-1"
        aria-labelledby="loginModalAjaxTitle"
        aria-modal="true"
        role="dialog">
      <div
          data-te-modal-dialog-ref
          class="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]">
        <div
            class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
          <div
              class="h-72 flex flex-shrink-0 items-start justify-end content-start rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50 bg-bg-registration-login bg-center bg-no-repeat">

              <!--Close button-->
              <button
                  @click="closeModal"
                  type="button"
                  class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  data-te-modal-dismiss
                  aria-label="Close">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="white"
                    class="h-6 w-6">
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>

            <!--Modal title-->

          </div>
          <div id="loader-div" class="relative flex justify-center">
            <div
                class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                    <span
                        class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span
                    >
            </div>
          </div>
          <!--Modal body-->
          <div class="relative p-4">
            <h5
              class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
              id="loginModalAjaxLabel">
            {{ $t('inlogscherm.login') }}
              </h5>
          </div>
          <div class="relative p-4">
            <div>
              <form @submit="formSubmit">
                <div class="form-result"></div>
                <div class="form-process"></div>
                <div id="loginModalMsg"
                    class="hidden mb-4 rounded-lg bg-danger-100 px-6 py-5 text-base text-danger-700"
                    role="alert">
                </div>
                <div class="mb-6">
                  <label for="username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ $t('inlogscherm.email') }}</label>
                  <input :placeholder="emailPlaceholder" type="email" v-model="username" name="username" id="username" class="border border-gray-300 text-gray-900 text-sm rounded focus:ring-0 focus:border-gray-400 block w-full p-3 dark:bg-zinc-700/30 dark:border-zinc-700/50 dark:text-gray-300/60 dark:placeholder:text-gray-300/60" >
                </div>
                <div class="mb-6">
                  <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ $t('inlogscherm.wachtwoord') }}</label>
                  <input :placeholder="passwordPlaceholder" name="password" type="password" v-model="password" id="password" class="border border-gray-300 text-gray-900 text-sm rounded focus:ring-0 focus:border-gray-400 block w-full p-3 dark:bg-zinc-700/30 dark:border-zinc-700/50 dark:text-gray-300/60 dark:placeholder:text-gray-300/60" >
                </div>
                <button type="submit"
                        class="btn bg-registration-button text-white w-full">{{ $t('inlogscherm.verstuur') }}</button>
              </form>

              <div class="mt-7 text-center">
                <router-link to="/password_forgotten" class="text-gray-800 dark:text-gray-300/60"><i class="mdi mdi-lock"></i> {{ $t('inlogscherm.wwvergeten') }}</router-link>
              </div>

              <div class="mt-4 mb-0 text-center">
                <router-link class="mb-0 text-gray-800 dark:text-white/80" :to="{ name: 'register_user', params :{ symposium_id: symposium_id}}" >{{ $t('inlogscherm.register') }}</router-link>
              </div>
            </div>
          </div>

          <!--Modal footer-->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import AanmeldingModalMethods from '../methods/AanmeldingModalMethods';
    import {
      Modal,
      initTE,
    } from "tw-elements";

    export default {
        mounted() {
            console.log('loginModalAjax mounted.');
            let currentObj = this;
            AanmeldingModalMethods.openTweModal(currentObj,'loginModalAjax', initTE,Modal);
            this.$root.$on('rerenderLoginModal', () => {
                currentObj.redirect();
            });
            currentObj.loader();
            currentObj.redirect();

        },
        data() {
            
            return {
              username: '',
              password: '',
              currentModal: '',
            };
        },
        computed: {
            emailPlaceholder: function(){
                return this.$t('inlogscherm.placeholder_email');
            },
            passwordPlaceholder: function(){
                return this.$t('inlogscherm.placeholder_password');
            },
            symposium_id: function(){
                return this.$route.query.symposium_id;
            },
            preregistration_key: function(){
               return this.$store.getters.getPreregistrationKey;
            }
        },
        methods: {
            formSubmit: function(e) {
                e.preventDefault();
                let currentObj = this;
                let store = this.$store;
                let symposium_id = currentObj.$route.query.symposium_id;
                currentObj.loader();
                axios.post('/login_ajax_aanmelding', {
                    email: this.username,
                    password: this.password,
                    symposium_id:symposium_id,
                    preregistration_key:currentObj.preregistration_key
                })
                .then(function (response) {
                    if(response.data.login=='fail'){
                        currentObj.hideloader();
                        document.getElementById('loginModalMsg').innerHTML = response.data.msg;
                        document.getElementById('loginModalMsg').classList.remove('hidden');
                        document.getElementById('password').value = '';
                    }else{
                        window.axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.CSRF;
                        currentObj.hideloader();
                        currentObj.currentModal.hide();
                        store.dispatch('initBlank');
                        store.dispatch('initWithData',response.data);
                        store.dispatch('renewSubSymposia',symposium_id);
                        AanmeldingModalMethods.gotoTheNextPage(store,currentObj.$parent.$router,response.data);                  	
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            redirect: function(){
                let currentObj = this;
                let store = this.$store;
                if(store.getters.getKlantId==''){
                    currentObj.hideloader();
                    return;
                }
                console.log('redirect');
                
                if(store.getters.getAanmelding.aanmelding.klant_id==''){
                    currentObj.hideloader();
                    return;
                }
                if(store.getters.getParticipants.length>1){
                    currentObj.hideloader();
                    if(currentObj.$parent.$router.currentRoute.path=='/choose_participant'){
                        return;
                    }
                    currentObj.$parent.$router.push({ path: 'choose_participant'});
                    return;
                }
                if(store.getters.getIsMultipleSymposium){
                    currentObj.hideloader();
                    if(currentObj.$parent.$router.currentRoute.path=='/choose_event'){
                        return;
                    }
                    currentObj.$parent.$router.push({ path: '/choose_event'});
                    return;
                }
                currentObj.hideloader();
                currentObj.$parent.$router.push({ path: 'registration_form'});
            },
            loader: function(){
                document.getElementById('loader-div').classList.remove('hidden');
                    // $('.page-transition-wrap-modal').removeClass('d-none');
            },
            hideloader: function(){
              if(document.getElementById('loader-div')){
                document.getElementById('loader-div').classList.add('hidden');
              }
            },
            closeModal: function(){
              this.currentModal.hide();
            }
        },
        created() {
            let currentObj = this;
            this.$store.watch(
              (state, getters) => getters.getKlantId,
              () => {
                console.log('store watching login klantid');
                currentObj.redirect();
              },
            )
            this.$store.watch(
                (state,getters) => getters.getParticipants,
                () => {
                    console.log('store watching login participants');
                    currentObj.redirect();
                }
            )
        }
    }
</script>