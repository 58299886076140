<template>
     <div class="container">

       <div
           data-te-modal-init
           class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
           id="ChooseEventModal"
           tabindex="-1"
           aria-labelledby="ChooseEventModalCenterTitle"
           aria-modal="true"
           role="dialog">
         <div
             data-te-modal-dialog-ref
             class="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]">
           <div
               class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
             <div
                 class="h-72 flex flex-shrink-0 items-start justify-end content-start rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50 bg-bg-registration-deelnemer
                 bg-center bg-no-repeat">
               <ErrorToast :error_messages="error_messages"></ErrorToast>
               <!--Close button-->
               <button
                   @click="closeModal"
                   type="button"
                   class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                   data-te-modal-dismiss
                   aria-label="Close">
                 <svg
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke-width="1.5"
                     stroke="white"
                     class="h-6 w-6">
                   <path
                       stroke-linecap="round"
                       stroke-linejoin="round"
                       d="M6 18L18 6M6 6l12 12" />
                 </svg>
               </button>

               <!--Modal title-->

             </div>

            <div class="card noborder divcenter" style="max-width: 500px;">
                <div class="card-body p-4">
                  <form @submit="formSubmit" id="subsymposia_form">
                  <h3 class="card-title font-body">{{ $t('aanmelding.Kies uw bijeenkomst') }}</h3>

                      <div class="form-process"></div>
                      <div class="col-12 form-group">
                        <div class="list-group">
                          <SubSymposiumPR v-for="subsymposium in items.models"
                                      :key="subsymposium.id"
                                      :subsymposium="subsymposium">

                          </SubSymposiumPR>
                        </div>
                      </div>

                      <div class="col-12 hidden">
                        <input type="text" id="modal-feedback-botcheck" name="modal-feedback-botcheck" value="" />
                      </div>
                      <div class="col-12">
                        <button type="submit" name="modal-feedback-submit"
                                class="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                        >{{ $t('aanmelding.Verstuur') }}</button>
                      </div>
                  </form>
                 </div>
            </div>
           </div>
         </div>
       </div>

     </div>
</template>

<script type = "text/javascript">
        import SubSymposiumPR from './SubSymposiumPRComponent';
        import AanmeldingModalMethods from '../methods/AanmeldingModalMethods';
        import ErrorToast from "./ErrorToastComponent";
        import {
          Toast,
          Modal,
          initTE,
        } from "tw-elements";
        import SubSymposiumPRComponent from "./SubSymposiumPRComponent";
        export default {
            mounted() {
                console.log('ChooseEventModal mounted.');
                let currentObj = this;
              AanmeldingModalMethods.openTweModal(currentObj,'ChooseEventModal', initTE,Modal);
              initTE({Toast});
            },
            data() {
                let store = this.$store;
                return {
                  items: store.getters.getSubSymposia,
                  current_modal : '',
                  error_messages : [],
                };
            },
            methods: {
                formSubmit(e) {
                    e.preventDefault();
                    let currentObj = this;
                    let store = this.$store;
                    var val = $('input[name="modal-feedback-cleanliness"]:checked', '#subsymposia_form').val();
                    var symposium_naam = $('input[name="modal-feedback-cleanliness"]:checked', '#subsymposia_form').parent().find('label').html();
                    store.dispatch('update_current_session',{symposium_id:val,symposium_naam:symposium_naam});
                    store.dispatch('setAanmeldopties');
                    currentObj.$parent.$router.push({ path: 'registration_form', query: { lang:currentObj.lang }});
                },
                closeModal: function(){
                  this.currentModal.hide();
                },
                addError: function(e) {
                  this.error_messages.push(e);
                },
                clearErrors: function(){
                  this.error_messages = [];
                },
            },
            components: {
                SubSymposiumPR,
                ErrorToast
            }
        }
      
</script>