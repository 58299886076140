<template>
	    <div class="form-group grid grid-cols-12 gap-4" v-bind:for="item.id">
	    	<div class="col-span-12 md:col-span-4 flex items-start">
	    		<span class="mt-1" v-html="naamTranslated"></span>
	    		<Toelichting :item="item"></Toelichting>
	    	</div>
	    	<div class="col-span-12 md:col-span-8">
	    		<div>
	    			<el-input
					  type="textarea"
					  :rows="2"
					  :name="item.id.toString()"
					  :placeholder="$t('aanmelding.Vul in')"
					  v-model="textarea" v-on:keyup.native="update_value">
					</el-input>
	    		</div>
	    	</div>
	    </div>
</template>

<script>
	import language from '../../methods/lang';
	import _ from 'lodash';
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
	import Toelichting from './ToelichtingComponent';
    export default {
    	props: ['item','lang'],
        mounted() {
            console.log('textareacomponent mounted.');
            this.set_input_value();
        },
        computed: {
		    naamTranslated: language.naamTranslated,
		    toelichtingTranslated: language.toelichtingTranslated
        },
        methods: {
        	update_value: function(event){
        		let currentObj = this;
        		let store = this.$store;
        		if(aanmeldingEntryMethods.aanmeldingEntryExists(this)){
        			this.aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
        			this.aanmeldingEntry.value = this.textarea;
        			store.dispatch('updateAanmeldingEntry',this.aanmeldingEntry);
        			window.setTimeout(function() { 
        				currentObj.aanmeldingEntry.value = currentObj.textarea;
        				store.dispatch('updateAanmeldingEntry',currentObj.aanmeldingEntry);
        			},2000);
        			return;
        		};
        		aanmeldingEntryMethods.createAanmeldingEntry(this,this.textarea);
        	},
        	set_input_value: function(){
        		let currentObj = this;
        		let store = this.$store;
        		if(aanmeldingEntryMethods.aanmeldingEntryExists(this)){
        			this.aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
        			this.textarea = this.aanmeldingEntry.value;
        			return;
        		};
        		this.textarea = '';
        	}
        },
        data() {
	      return {
	        textarea: ''
	      }
	    },
		created() {
		    this.$store.watch(
		      (state, getters) => getters.getAanmeldEntries.models,
		      () => {
		        this.set_input_value();
		      },
		    );
		    this.$store.watch(
		      (state, getters) => getters.getCurrentSessieId,
		      () => {
		      	this.set_input_value();
		      }
		    );
  		},
	    components: {
  			Toelichting
	    }
    }
</script>