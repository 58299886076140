<template>
  <div class="container">
    <div
      data-te-modal-init
      class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
      id="finishRegistrationModal"
      tabindex="-1"
      aria-labelledby="finishRegistrationModalCenterTitle"
      aria-modal="true"
      role="dialog">
    <div
        data-te-modal-dialog-ref
        class="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]">
      <div
          class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
        <div
            class="h-72 flex flex-shrink-0 items-start justify-end content-start rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50 bg-bg-registration-deelnemer bg-center bg-no-repeat">

          <!--Close button-->
          <button
              @click="closeModal"
              type="button"
              class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
              data-te-modal-dismiss
              aria-label="Close">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="white"
                class="h-6 w-6">
              <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <!--Modal title-->

        </div>
        <div id="loader-div" class="relative flex justify-center">
          <div
              class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status">
                    <span
                        class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span
                    >
          </div>
        </div>
        <!--Modal body-->
        <div class="card-body p-4" v-html="message" ></div>

        <!--Modal footer-->

      </div>
    </div>
  </div>
  </div>
</template>

<script>
    import AanmeldingModalMethods from '../methods/AanmeldingModalMethods';
    import {
      Modal,
      initTE,
    } from "tw-elements";

    export default {
      mounted() {
        console.log('aanmelding afronden modal');
        let currentObj = this;
        AanmeldingModalMethods.openTweModal(currentObj,'finishRegistrationModal', initTE,Modal);
        axios.post('/aanmelding_afronden_message', {
          aanmelding_id: currentObj.aanmelding_id,
          lang: currentObj.lang
        })
            .then(function (response) {
              currentObj.message = response.data.message;
              axios.get('/logout');
            })
            .catch(function (error) {
              console.log(error);
            });
        this.hideloader();
      },
      props: ['aanmelding_id', 'lang'],
      data() {
        return {
          message: '',
          currentModal: '',
        }
      },
      methods: {
        closeModal: function () {
          this.currentModal.hide();
          window.location.href = '/logout';
        },
        loader: function () {
          document.getElementById('loader-div').classList.remove('hidden');
        },
        hideloader: function () {
          document.getElementById('loader-div').classList.add('hidden');
        },
      }
    }
</script>