<template>

		<button v-if="displayToelichting"
            type="button"
            role="button"
            tabindex="0"
            class="px-2 py-0"
            :id="buttonId"
            data-te-toggle="popover"
            data-te-placement="top"
            data-te-html="true"
            :data-te-trigger="trigger"
            :data-te-template="popoverTemplate"
            :data-te-content="toelichtingTranslated"
            >
              <i class="mdi mdi-help-circle text-lg  text-project-default"></i>
    </button>

</template>

<script>
import language from '../../methods/lang';
  import {
    Popover,
    initTE,
  } from "tw-elements";
import aanmeldingEntryMethods from "../../methods/AanmeldingEntryMethods";



export default {
    	props: ['item','lang'],
        mounted() {
            console.log('toelichtingcomponent mounted.');
            if(!this.displayToelichting){
              return;
            }
            // initTE({ Popover});
            let exampleEl = document.getElementById(this.buttonId);
            let options = {   toggle:"popover",
                              placement:"top",
                              html: true,
                              trigger: this.trigger,
                              template: this.popoverTemplate,
                              content: this.toelichtingTranslated
                        };
            this.popoverObj = new Popover(exampleEl, options);
            this.popoverEvents();
        },
        data() {
          return {
              popoverTemplate: '<div class="opacity-0 transition-opacity duration-150 ease-in-out absolute top-0 left-0 z-[1070] block max-w-[400px] break-words bg-white bg-clip-padding border border-neutral-100 rounded-lg shadow-[0_0px_3px_0_rgba(0,0,0,0.07),0_2px_2px_0_rgba(0,0,0,0.04)] text-sm not-italic font-normal text-left no-underline underline-offset-auto normal-case leading-6 tracking-normal break-normal whitespace-normal dark:bg-neutral-700 dark:border-0 dark:text-white data-[popper-reference-hidden]:hidden" role="tooltip"><h3 class="popover-header py-2 px-4 mb-0 border-b-2 border-neutral-100 rounded-t-lg font-medium empty:hidden dark:border-neutral-500"></h3><div class="popover-body p-4 text-[#212529] dark:text-white list-disc"></div></div>',
              buttonId: "button_toelichting_"+this.item.id,
              popoverObj: {},
          }
        },
        computed: {
            toelichtingTranslated: language.toelichtingTranslated,
            displayToelichting: function(){
              if(this.toelichtingTranslated==''||!this.toelichtingTranslated){
                return false;
              }
              return true;
            },
            trigger: function(){
              if(this.isSafariAgent){
                return 'click'
              }
              return 'focus';
            },
            isSafariAgent: function(){
              let chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;
              let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
              if ((chromeAgent) && (safariAgent)) safariAgent = false;
              return safariAgent;
            }
        },
        methods: {
          popoverEvents: function(){
            let currentObj = this;
            if(!this.isSafariAgent){
               return;
            }
            if(!this.displayToelichting){
               return;
            }
            const myPopoverTrigger = document.getElementById(currentObj.buttonId)
            myPopoverTrigger.addEventListener('shown.te.popover', () => {
               myPopoverTrigger.focus();
               myPopoverTrigger.addEventListener('focusout', () => {
                    currentObj.popoverObj.hide();
               });
            });
          },

        },

    }
</script>